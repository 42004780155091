var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _vm._m(0),
      _c(
        "draggable",
        {
          attrs: { filter: ".forbid", move: _vm.onMove },
          model: {
            value: _vm.list,
            callback: function ($$v) {
              _vm.list = $$v
            },
            expression: "list",
          },
        },
        [
          _c(
            "transition-group",
            _vm._l(_vm.list, function (element, index) {
              return _c(
                "a-button",
                {
                  key: element,
                  class: index === 1 ? "item forbid" : "item",
                  attrs: { type: "primary" },
                },
                [_vm._v(" " + _vm._s(element) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _vm._v(" 导出的列表包含如下字段 "),
      _c("span", [_vm._v("可拖拽改变显示顺序")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }